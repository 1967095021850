// AlertBox
document.addEventListener('click',function(e){
    if(e.target && (e.target.id === 'alertbox-close')){
        hideAlertbox(e.target.parentElement);
    }
});

function hideAlertbox(element) {
    element.classList.add('hide');
}

function showAlertbox(type, msg) {
    console.log('tuu 1');
    let alertbox = null;
    let alertbox_msg = '';
    if (type === 'success') {
        alertbox = document.querySelector('.alertbox-success');
        alertbox_msg = document.querySelector('.alert-success-msg');
    }
    if (type === 'error') {
        alertbox = document.querySelector('.alertbox-danger');
        alertbox_msg = document.querySelector('.alert-danger-msg');
    }
    console.log('tuu 2');
    if (alertbox) {
        console.log('tuu 3');
        alertbox.classList.remove('hide');
        alertbox_msg.innerText = msg;
        setInterval(hideAlertbox, 3500, alertbox);
    }
}

// Contact form send
document.getElementById("contactForm").addEventListener("submit", function (e) {
    e.preventDefault();
    let url = this.dataset.url;
    let formButton = document.querySelector('.form__button');

    formButton.disabled = true;

    let formData = {};
    let data = new FormData(this);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    fetch(url, {
        method: 'POST',
        credentials: "same-origin",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].getAttribute('content')
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {

            formButton.disabled = false;

            if (data.status === 1) {
                this.reset();
                // notify('success', data.msg);
                showAlertbox('success', data.msg);
            } else {
                let obj = data.msg;
                let message = '';
                Object.keys(obj).map(function(key) {
                    message += obj[key] + '\n';
                });
                // notify('error', message);
                showAlertbox('error', message);
            }
        });
});

